// 2. Define some routes
// Each route should map to a component. The "component" can
// either be an actual component constructor created via
// `Vue.extend()`, or just a component options object.
// We'll talk about nested routes later.
// import About from "@/views/About";
import {createRouter, createWebHistory} from "vue-router";

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import("@/views/Home.vue"),
    },
    {
        path: "/about",
        name: "About",
        component: () => import("@/views/About.vue"),
    },
    {
        path: "/what-is-ftc",
        name: "What is FTC",
        component: () => import("@/views/WhatIsFTC.vue"),
    },
    {
        path: "/robot",
        name: "Robot",
        component: () => import("@/views/Robot"),
    },
    {
        path: "/team",
        name: "Team",
        component: () => import("@/views/Team"),
    },
    {
        path: "/sponsors",
        name: "Sponsors",
        component: () => import("@/views/Sponsors"),
    },
    {
        path: "/blog",
        name: "Blog",
        component: () => import("@/views/BlogList"),
    },
    {
        path: "/blog/:date",
        name: "BlogPost",
        component: () => import("@/views/BlogPost"),
    },

    {path: "/:catchAll(.*)", name: "404", component: () => import("@/views/404")},

    {
        path: "/iframes/rankings",
        name: "Rankings iframe",
        component: () => import("@/components/iframes/Scoreboard.vue"),
    },
    {
        path: "/rankings",
        alias: "/iframes/rankings",
        name: "Rankings iframe",
        component: () => import("@/components/iframes/Scoreboard.vue"),
    },
    {
        path: "/iframes/generator",
        name: "Generator iframe",
        component: () => import("@/views/iframes/Generator.vue"),
    },
    {
        path: "/iframes/awards",
        name: "Awards iframe",
        component: () => import("@/components/iframes/banners/Banners.vue"),
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
